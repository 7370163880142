<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">카테고리</h2>
      </div>
      <div class="body_section">
        <TableHead>
          <template v-if="!isDisabled" v-slot:headLeft>
            <p class="desc_tip">
              <span class="ico_purchase ico_exclam" />아이콘을 선택 후 위&#47;아래로 이동하시면
              순서를 변경 하실 수 있습니다.
            </p>
          </template>
        </TableHead>
        <div class="group_form">
          <CategoryDragGroup
            ref="group0"
            title="대분류"
            :isDisabled="isDisabled"
            :depth="0"
            :parentId="0"
            :dataListDefault="kindList0"
            @onChangedKind="onChangedKind0"
            @alert="alert"
          />
          <CategoryDragGroup
            ref="group1"
            title="중분류"
            :isDisabled="isDisabled"
            :depth="1"
            :parentId="category0"
            :dataListDefault="kindList1"
            @onChangedKind="onChangedKind1"
            @alert="alert"
          />
          <CategoryDragGroup
            ref="group2"
            title="소분류"
            :isDisabled="isDisabled"
            :depth="2"
            :parentId="category1"
            :dataListDefault="kindList2"
            @onChangedKind="onChangedKind2"
            @alert="alert"
          />
        </div>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import CategoryDragGroup from "@/components/admin/system/category/CategoryDragGroup.vue";
import { CATEGORY_ACTION } from "@/store/modules/category/action";

import PageMixin from "@/mixins/PageMixin";
import LocalStorageManager from "@/LocalStorageManager";

// draggable library ex)
// https://codesandbox.io/s/jvv01pzw8v

export default {
  name: "CategoryManagement",
  components: {
    PageWithLayout,
    TableHead,
    CategoryDragGroup,
  },
  mixins: [PageMixin],
  props: {
    currentId: String,
  },
  data() {
    return {
      category0: null,
      category1: null,
      category2: null,
    };
  },
  computed: {
    isDisabled() {
      return false;
    },
    kindList0() {
      const arr = this.$store.state.category.categoryList0;

      // 최상위 cateNum 은 0.
      const cateNum = 0;

      const itemCate = arr.find((item) => item.parentCateNum === cateNum);
      if (!itemCate) return [];

      return itemCate.dataList;
    },
    kindList1() {
      if (!this.category0) return [];

      const arr = this.$store.state.category.categoryList1;

      const cateNum = this.category0;

      const itemCate = arr.find((item) => item.parentCateNum === cateNum);
      if (!itemCate) return [];

      return itemCate.dataList;
    },
    kindList2() {
      if (!this.category1) return [];

      const arr = this.$store.state.category.categoryList2;

      const cateNum = this.category1;

      const itemCate = arr.find((item) => item.parentCateNum === cateNum);
      if (!itemCate) return [];

      return itemCate.dataList;
    },
  },
  mounted() {
    this.$store.dispatch(CATEGORY_ACTION.GET_LIST_0);
  },
  methods: {
    onChangedKind0(newId) {
      if (this.category0 === newId || newId === null) return;

      this.category0 = newId;

      this.category1 = null;
      this.category2 = null;

      this.$store.dispatch(CATEGORY_ACTION.GET_LIST_1, newId);
    },
    onChangedKind1(newId) {
      if (this.category1 === newId || newId === null) return;

      this.category1 = newId;
      this.category2 = null;

      this.$store.dispatch(CATEGORY_ACTION.GET_LIST_2, newId);
    },
    onChangedKind2(newId) {
      if (this.category2 === newId || newId === null) return;

      this.category2 = newId;
    },
  },
};
</script>
