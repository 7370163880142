<template>
  <div class="item_drag">
    <Input
      class="w300"
      :value.sync="addValueSync"
      :isFocusOnMount="true"
      @onEnterKey="onCompleteAddWrite"
    />
    <div class="area_right">
      <button type="button" class="btn_save" @click="onCompleteAddWrite">
        <span class="ico_purchase ico_check_save">저장</span>
      </button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/common/input/Input";

export default {
  name: "CategoryWriteItem",
  components: {
    Input,
  },
  props: {
    title: String,
    addValue: String,
  },
  computed: {
    addValueSync: {
      get() {
        return this.addValue;
      },
      set(value) {
        this.$emit("update:addValue", value);
      },
    },
  },
  methods: {
    onCompleteAddWrite() {
      this.$emit("onCompleteAddWrite");
    },
  },
};
</script>
