<template>
  <div class="drag_comm w388">
    <h3 class="tit_drag">
      {{ title }}
    </h3>
    <div class="area_drag">
      <CategoryWriteItem
        v-if="isAddMode"
        :addValue.sync="addValue"
        @onCompleteAddWrite="onCompleteAddWrite"
      />
      <draggable v-if="dataList.length > 0" v-model="dataList" @end="onEndDrag">
        <transition-group>
          <template v-for="item in dataList">
            <div
              v-if="currentModifyCateNum !== item.cateNum"
              :key="item.cateNum"
              :class="['item_drag', { on: currentId === item.code }]"
              @click="onClickItem(item)"
            >
              {{ item.desc }}

              <div class="area_right">
                <span class="item_cateCode">{{ item.cateCode }}</span>
                <span v-if="!isModifyMode" class="ico_purchase ico_drag">순서변경</span>
                <button
                  v-if="isModifyMode"
                  type="button"
                  class="btn_edit"
                  @click.stop="onClickModifyItem(item)"
                >
                  <span class="ico_purchase ico_edit">수정</span>
                </button>
                <button
                  v-if="isModifyMode"
                  type="button"
                  class="btn_delete"
                  @click.stop="onClickRemoveItem(item.cateNum)"
                >
                  <span class="ico_purchase ico_delete">삭제</span>
                </button>
              </div>
            </div>
            <CategoryWriteItem
              v-else
              :key="item.cateNum"
              :addValue.sync="modifyValue"
              @onCompleteAddWrite="onCompleteModifyItem(item)"
            />
          </template>
        </transition-group>
      </draggable>
      <p v-if="dataList.length < 1" class="desc_empty">
        {{ parentId == null ? "상위 분류를 선택하세요" : title + "를 등록해주세요" }}
      </p>
    </div>
    <div v-if="parentId !== null" class="area_util">
      <div class="area_right">
        <button
          v-if="!isAddMode && !isModifyMode"
          type="button"
          class="btn_small btn_fourthly"
          @click="onClickAddMode"
        >
          추가
        </button>
        <button
          v-if="!isAddMode && !isModifyMode && dataList.length > 0"
          type="button"
          class="btn_small btn_fourthly"
          @click="onClickModifyMode"
        >
          편집
        </button>
        <button
          v-if="isAddMode"
          type="button"
          class="btn_small btn_secondary"
          @click="onCompleteModifyMode"
        >
          취소
        </button>
        <button
          v-if="isModifyMode"
          type="button"
          class="btn_small btn_tertiary"
          @click="onCompleteModifyMode"
        >
          완료
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

import Input from "@/components/common/input/Input";
import CategoryWriteItem from "./CategoryWriteItem";

import LocalStorageManager from "@/LocalStorageManager";
import ApiService from "@/services/ApiService";

import { CATEGORY_ACTION } from "@/store/modules/category/action";

export default {
  name: "CategoryDragGroup",
  components: {
    draggable,
    Input,
    CategoryWriteItem,
  },
  props: {
    title: String,
    dataListDefault: Array,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    depth: Number,
    parentId: Number,
  },
  data() {
    return {
      dataList: this.dataListDefault,
      currentId: "",
      isAddMode: false,
      addValue: "",
      modifyValue: "",
      isModifyMode: false,
      currentModifyCateNum: null,
    };
  },
  watch: {
    dataListDefault: function (val) {
      this.dataList = val;
      this.isAddMode = false;
      this.isModifyMode = false;
      this.currentModifyCateNum = null;
      if ((this.currentId = "")) return;
      const iscorrectID = this.dataList.map((item) => item.cateNum).indexOf(this.currentId) > -1;
      if (!iscorrectID) {
        this.currentId = "";
      }
    },
  },

  methods: {
    onClickAddMode() {
      this.addValue = "";
      this.isAddMode = true;
      this.currentModifyCateNum = null;
      this.currentId = "";
      this.$emit("onChangedKind", null);
    },
    onClickModifyMode() {
      this.isAddMode = false;
      this.isModifyMode = true;
      this.currentModifyCateNum = null;
      this.currentId = "";
      this.$emit("onChangedKind", null);
    },
    onCompleteModifyMode() {
      this.isModifyMode = false;
      this.isAddMode = false;
      this.currentModifyCateNum = null;
    },
    onClickItem(item) {
      this.isAddMode = false;
      this.currentModifyCateNum = null;
      if (this.isModifyMode) {
        this.onClickModifyItem(item);
      } else {
        this.currentId = item.cateNum;
        this.$emit("onChangedKind", item.cateNum);
      }
    },
    onClickModifyItem(item) {
      this.modifyValue = item.title;
      this.currentModifyCateNum = item.cateNum;
    },
    onCompleteModifyItem(item) {
      const title = this.modifyValue.trim();
      if (!title) return;

      this.currentModifyCateNum = null;

      const obj = Object.assign({}, item, { title: title });

      this.modify(item.cateNum, obj);
    },
    async modify(cateNum, obj) {
      const path = `${this.$apiPath.CATEGORY}/${cateNum}`;

      const result = await ApiService.shared.putData(path, obj);
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.refresh();
    },
    onEndDrag(e) {
      if (e.newIndex === e.oldIndex) return;

      // const item = this.dataList[ e.newIndex ];

      // const obj = Object.assign( {}, item, { cateOrder: e.newIndex + 1 } );

      const oldItem = this.dataListDefault[e.newIndex];
      const newItem = this.dataList[e.newIndex];

      const obj = Object.assign({}, newItem, { cateOrder: oldItem.cateOrder });

      this.modify(newItem.cateNum, obj);
    },
    alert(text) {
      this.$emit("alert", text);
    },
    async onClickRemoveItem(cateNum) {
      const path = `${this.$apiPath.CATEGORY}/${cateNum}`;

      const result = await ApiService.shared.deleteData(path);
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.refresh();
    },
    async onCompleteAddWrite() {
      const title = this.addValue.trim();
      if (!title) return;

      this.isAddMode = false;

      const obj = {
        cateCode: "K001",
        title: this.addValue,
      };

      let path = this.$apiPath.CATEGORY;
      if (this.depth !== 0) path += `/${this.parentId}`;

      const result = await ApiService.shared.postData(path, obj);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.refresh();
    },
    refresh() {
      switch (this.depth) {
        case 0:
          this.$store.dispatch(CATEGORY_ACTION.GET_LIST_0);
          break;
        case 1:
          this.$store.dispatch(CATEGORY_ACTION.GET_LIST_1, this.parentId);
          break;
        case 2:
          this.$store.dispatch(CATEGORY_ACTION.GET_LIST_2, this.parentId);
          break;
      }
    },
  },
};
</script>

<style scoped>
/* -- 드레그 -- */
.drag_comm {
  display: inline-block;
  position: relative;
  padding-bottom: 49px;
  border-top: 1px solid #222;
  vertical-align: top;
}
.drag_comm + .drag_comm {
  margin-left: 38px;
}
.drag_comm .tit_drag {
  padding: 15px;
  border-bottom: 1px solid #e1e1e1;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  background-color: #fbfbfb;
  color: #222;
  text-align: center;
}
/* 드레그 아이템 */
.area_drag .item_drag {
  overflow: hidden;
  width: 100%;
  padding: 8px 24px 7px 24px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 12px;
  line-height: 33px;
  background-color: #fff;
  color: #555;
  box-sizing: border-box;
  cursor: grab;
}
.area_drag .item_drag .ico_drag {
  margin: 11px 0;
}
.area_drag .item_drag .btn_save {
  display: inline-block;
  margin: 0;
  padding: 4px 0;
  vertical-align: top;
}
.area_drag .item_drag .btn_edit {
  display: inline-block;
  margin: 0;
  padding: 7px 0 6px;
  vertical-align: top;
}
.area_drag .item_drag .btn_delete {
  display: inline-block;
  margin-left: 20px;
  padding: 7px 0 6px;
  vertical-align: top;
}
.area_drag .item_drag.on {
  position: relative;
  z-index: 10;
  padding: 7px 23px 7px 23px;
  border: 1px solid #5551ce;
  background-color: #fbfbfb;
}
.area_drag .item_drag.sortable-ghost {
  opacity: 0.01;
}
.area_drag .item_drag.sortable-drag:hover {
  border: 0 none;
}
.area_drag .item_drag .item_cateCode {
  display: inline-block;
  padding-right: 5px;
}

/* 드레그 영역 */
.area_drag {
  overflow-x: hidden;
  overflow-y: auto;
  height: 489px;
  background-color: #f9fafd;
}
/* 빈영역 */
.area_drag .desc_empty {
  display: inline-block;
  width: 100%;
  padding: 235px 0;
  font-size: 12px;
  line-height: 18px;
  color: #5551ce;
  border-bottom: 1px solid #e1e1e1;
  text-align: center;
}
</style>
